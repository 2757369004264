span {
    display: none;
}

input:invalid[focused="true"] {
    border: 1px solid red;
}

input:invalid[focused="true"]~span {
    display: block;
}